<template>
  <div>
    <v-banner class="lavBanner" single-line height="400" color="#F5F8FD">
      <v-img
        src="https://h5.ophyer.cn/official_website/other/custometHomeBanner.png"
        height="100%"
        :transition="false"
        @load="handleLoad"
        class="d-flex align-center"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center mt-12">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center" style="color:#FFF">
              <div>
                <v-card-title class="font-size-48 white--text">
                  根据客户需求量身定制运营玩法
                </v-card-title>
                <v-card-title class="font-size-18 justify-center white--text">
                  专注大客户的运营定制化服务
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- :class="index % 2 === 0 ? 'red': ''" -->
    <v-container class="wrap1 " > 
        <v-row v-for="(item,index) in data.itemList" :key="index"  class="align-center px-14">
          <v-col cols="2"></v-col>
          <v-col cols="4" :class="index == 1?'':'my-15'" style="padding:0" >
            <v-card flat color='transparent'>
              <div class='d-flex justify-center'>
                <v-img v-if="index % 2 == 0" :src="item.src" height="260" width="400" class="flex-grow-0 rounded-xl">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        size="80"
                        color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              <div class="text-align-right" style="padding-left:100px; padding-right:100px;">
                <v-card-title v-if="index % 2 != 0" class="justify-end font-size-24  font-weight-bold" v-text="item.title"></v-card-title>
                <v-card-text v-if="index % 2 != 0" class="font-size-14 "  v-text="item.content" style="color:#666"></v-card-text>
              </div>
            </v-card>
          </v-col>
          
          <v-col cols="4" :class="index == 0?'':'my-15'" style="padding:0">
            <v-card flat  color='transparent'>
              <div class='d-flex justify-start'>
                <v-img v-if="index % 2 != 0" :src="item.src" height="260" width="400" class="flex-grow-0 rounded-xl">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        size="80"
                        color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              <div style="width:400px;" > 
                <v-card-title v-if="index % 2 == 0" class=" font-size-24 font-weight-bold " v-text="item.title"  style="width:400px;"></v-card-title>
                <v-card-text style="color:#666" v-if="index % 2 == 0" class="font-size-14 "  v-text="item.content" ></v-card-text>
              </div>
            </v-card>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
let _this
import lavData from '@/static/lavData.js'
export default {
  name: "IntelligentCulturalTourism",
  data: () => ({
    imgLoad: false,
    windowHight: window.innerHeight/2,
    data:lavData.customersSolution
  }),
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    }
}
};
</script>

<style lang="scss" scoped>
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
::v-deep .v-card__text{
  line-height: 20px!important;
}

.wrap1 .row:nth-child(2n){
  background: #F5F9FD;
}
</style>